import { useStore } from 'effector-react';
import { $isLead } from '../../stores/usuario/usuario.store';
import S from './header.styles';
import { HeaderProps } from './header.types';

export const Header: React.FC<HeaderProps> = ({ logo, center, aside }) => {
  const isLead = useStore($isLead);

  return (
    <S.Container isLead={isLead} data-testid="header" data-section="section_header">
      {logo}
      {center}
      {aside}
    </S.Container>
  );
};
