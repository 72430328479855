export class CursosNaoEncontradosException extends Error {
  constructor() {
    super();
    this.name = 'CursosNaoEncontradosException';
  }
}

// API
export interface CursoResponseBody {
  id: string;
  codigo: number;
  nome: string;
  tipo: string;
  modalidade: string;
  numHabilitacao?: number | null;
}

// Service
export interface ConsultarCursosParams {
  token: string;
}

export type ConsultarCursosService = (params: ConsultarCursosParams) => Promise<CursoResponseBody[]>;
