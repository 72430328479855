import { BrowserCacheLocation } from '@azure/msal-browser';
import { portal } from '../../environment/environment.configs';
import { IAuthConfig } from '../auth.types';
import { loggerCallback } from '../logger/logger';

export const estacio: IAuthConfig[] = [
  {
    envs: ['local'],
    brands: ['estacio'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'e37af882-da85-4833-8d0b-f5a71a215496',
          authority: 'https://estaciodev.b2clogin.com/estaciodev.onmicrosoft.com/B2C_1_signin_sava_candidatos',
          knownAuthorities: ['estaciodev.b2clogin.com'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `${portal}/auth-external?origin=sava`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://estaciodev.onmicrosoft.com/e37af882-da85-4833-8d0b-f5a71a215496/user.read',
      },
    },
    applicationUrl: 'http://localhost:3000',
  },
  {
    envs: ['local'],
    brands: ['estacio'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'bd215e16-5d5c-4152-b918-485d25e06e44',
          authority: 'https://estaciodev.b2clogin.com/estaciodev.onmicrosoft.com/b2c_1_signin',
          knownAuthorities: ['estaciodev.b2clogin.com'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `https://dev.portal.estacio.br/experimente`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://estaciodev.onmicrosoft.com/bd215e16-5d5c-4152-b918-485d25e06e44/user.read',
      },
    },
    applicationUrl: 'http://localhost:3005',
  },
  {
    envs: ['dev'],
    brands: ['estacio'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'e37af882-da85-4833-8d0b-f5a71a215496',
          authority: 'https://estaciodev.b2clogin.com/estaciodev.onmicrosoft.com/B2C_1_signin_sava_candidatos',
          knownAuthorities: ['estaciodev.b2clogin.com'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `${portal}/auth-external?origin=sava`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://estaciodev.onmicrosoft.com/e37af882-da85-4833-8d0b-f5a71a215496/user.read',
      },
    },
    applicationUrl: 'https://candidato.estacio.sava.dev.yduqs.com.br',
  },
  {
    envs: ['dev'],
    brands: ['estacio'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'bd215e16-5d5c-4152-b918-485d25e06e44',
          authority: 'https://estaciodev.b2clogin.com/estaciodev.onmicrosoft.com/b2c_1_signin',
          knownAuthorities: ['estaciodev.b2clogin.com'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `https://dev.portal.estacio.br/experimente`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://estaciodev.onmicrosoft.com/bd215e16-5d5c-4152-b918-485d25e06e44/user.read',
      },
    },
    applicationUrl: 'https://experimente.dev.estacio.br',
  },
  {
    envs: ['qld'],
    brands: ['estacio'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: '8fbd2814-d472-447f-b05a-268b13b964dd',
          authority: 'https://estacioqas.b2clogin.com/estacioqas.onmicrosoft.com/B2C_1_signin_sava_candidatos',
          knownAuthorities: ['estacioqas.b2clogin.com'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `${portal}/auth-external?origin=sava`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://estacioqas.onmicrosoft.com/8fbd2814-d472-447f-b05a-268b13b964dd/user.read',
      },
    },
    applicationUrl: 'https://candidato.estacio.sava.qld.yduqs.com.br',
  },
  {
    envs: ['qld'],
    brands: ['estacio'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: '23219988-8cb3-48f1-af90-5926729b0d61',
          authority: 'https://estacioqas.b2clogin.com/estacioqas.onmicrosoft.com/B2C_1_signin',
          knownAuthorities: ['estacioqas.b2clogin.com'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `https://qa.portal.estacio.br/experimente`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://estacioqas.onmicrosoft.com/23219988-8cb3-48f1-af90-5926729b0d61/user.read',
      },
    },
    applicationUrl: 'https://experimente.qa.estacio.br',
  },
  {
    envs: ['prd'],
    brands: ['estacio'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: '61aa1062-77e6-4368-99a8-15461de025a1',
          authority: 'https://login.estacio.br/loginestacio.onmicrosoft.com/B2C_1_signin_sava_candidatos',
          knownAuthorities: ['login.estacio.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `${portal}/auth-external?origin=sava`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://loginestacio.onmicrosoft.com/61aa1062-77e6-4368-99a8-15461de025a1/user.read',
      },
    },
    applicationUrl: 'https://candidatos.sava.estacio.br',
  },
  {
    envs: ['prd'],
    brands: ['estacio'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'e62ba000-3cf5-463c-bfb2-2a632612a99a',
          authority: 'https://login.estacio.br/loginestacio.onmicrosoft.com/B2C_1_signin',
          knownAuthorities: ['login.estacio.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `https://estacio.br/experimente`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://loginestacio.onmicrosoft.com/e62ba000-3cf5-463c-bfb2-2a632612a99a/user.read',
      },
    },
    applicationUrl: 'https://experimente.estacio.br',
  },
];
