import { Assert } from '@aura/core-lib';
import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import useSWR, { mutate as globalMutate } from 'swr';
import { consultarCursosService } from '../../features/disciplinas/services/consultar-cursos/consultar-cursos.service';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { $autenticacao } from '../../stores/autenticacao/autenticacao.store';
import { $listaCurso, atualizaListaCurso } from '../../stores/lista-curso/lista-curso.store';
import { $credenciais, $isLead, $usuario } from '../../stores/usuario/usuario.store';
import { CursosResult } from './cursos.types';

export const useCursos = (): CursosResult => {
  const { apiConfigs } = ConfigsManager.getInstance();
  const { tempoDedupping } = apiConfigs;
  const { token } = useStore($autenticacao);
  const idExterno = useStore($credenciais);
  const isLead = useStore($isLead);
  const usuario = useStore($usuario);

  const chaveConsulta = useMemo(() => {
    if (!token) return null;

    return ['lista-curso', idExterno];
  }, [idExterno, token]);

  const {
    data: listaCursoResponse,
    isValidating,
    error: erro,
    mutate,
  } = useSWR(
    chaveConsulta,
    async () => {
      Assert.nonNullable(token);
      return isLead && usuario ? await consultarCursosService({ token }) : [];
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateOnReconnect: true,
      revalidateOnMount: true,
      dedupingInterval: tempoDedupping,
      onSuccess: (data) => {
        atualizaListaCurso(data);
      },
    },
  );

  const listaCurso = useStore($listaCurso);

  useEffect(() => {
    if (!listaCurso && listaCursoResponse) {
      atualizaListaCurso(listaCursoResponse);
    }
  }, [listaCursoResponse, listaCurso]);

  useEffect(() => {
    if (token && chaveConsulta) {
      globalMutate(chaveConsulta, undefined);
    }
  }, [token, chaveConsulta]);

  const pendente = useMemo(() => (!listaCurso && !erro) || isValidating, [listaCurso, erro, isValidating]);

  const retentar = useCallback(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    if (isLead && usuario) {
      retentar();
    }
  }, [isLead, usuario, retentar]);

  return { listaCurso, pendente, erro, retentar };
};
