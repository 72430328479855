import axios, { isAxiosError } from 'axios';
import { ConfigsManager } from '../../../../infra/configs/configs.manager';
import { ConsultarCursosService, CursoResponseBody, CursosNaoEncontradosException } from './consultar-cursos.types';

const {
  apiConfigs: { api },
} = ConfigsManager.getInstance();

export const consultarCursosService: ConsultarCursosService = async ({ token }) => {
  const url = `${api}/cursos`;

  const headers = {
    authorization: `Bearer ${token}`,
  };

  try {
    const { data } = await axios.get<CursoResponseBody[]>(url, { headers });

    return data;
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      throw new CursosNaoEncontradosException();
    }

    throw err;
  }
};
