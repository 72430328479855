import styled from '@emotion/styled';

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  min-width: 32px;
  max-width: 32px;

  figure {
    margin: 0;
  }
`;

const SkeletonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;

  [data-lift='lft-skeleton'] {
    &:last-child {
      max-width: 100px;
    }
  }
`;

const S = {
  AvatarWrapper,
  SkeletonWrapper,
};

export default S;
