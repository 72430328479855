import styled from '@emotion/styled';

const ListaTags = styled.div`
  background: ${({ theme }) => theme.NeutralColorLightPure};
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
`;

const Container = styled.div`
  width: 100%;
  width: 25rem;
  position: relative;
  margin-right: 31px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const ListaCursos = styled.div`
  height: 17.5rem;
  overflow: auto;
  border-radius: 0 0 0.5rem 0.5rem;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.16);
  background: ${({ theme }) => theme.NeutralColorLightPure};
  z-index: 1;
`;

const S = {
  ListaTags,
  Container,
  Content,
  ListaCursos,
};

export default S;
