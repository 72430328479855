import { Event } from 'effector';
import { Curso, Disciplina, IdExterno, Usuario } from '../../../../models/usuario.model';

export enum TiposConteudo {
  NENHUM = '0',
  DISCIPLINAS = 'disciplinas',
  CURSOS_COMPLEMENTARES = 'complementar',
  DISCIPLINAS_E_CURSOS_COMPLEMENTARES = '2',
}

export interface PushDadosUsuarioNivel1 {
  event: 'registration_data';
  details: {
    inscricao: {
      usuarioLead: boolean | null;
      idInscricao: number | string | null;
      idCandidato: number | null;
    };
  };
}

export interface PushDadosUsuario {
  event: 'registration_data';
  details: {
    inscricao: {
      idLead: string | undefined;
      idInscricao: number | string | null | undefined;
      idCandidato: number | null | undefined;
      tipoCurso: string | undefined;
      curso: string | undefined;
      modalidade: string | undefined;
      nomInstituicao: string | undefined;
    };
    matricula: {
      codMatricula: string | undefined;
    };
    conteudo: {
      tipoConteudo: TiposConteudo;
    };
  };
}

export interface CursoSelecionado {
  nomeCurso: string;
  tipoCurso: string;
  disciplinas?: Disciplina[];
}

export interface PushDadosUsuarioDatalayerParams {
  idExterno: IdExterno | null | undefined;
  usuario?: Usuario | null;
  possuiDisciplinas: boolean;
  possuiCursosComplementares: boolean;
}

export type BaseEvents = {
  usuario: Event<Usuario>;
  codigosCursos: Event<string[]>;
};

export type RegistrationDataEvents = BaseEvents & {
  gradeCurricular?: Event<Curso>;
};
