import { LftLink } from '@lift/design-system-react-web/dist/components';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from '../../utils/cookie/remove-cookie';
import S from './logout.styles';

export const Logout = () => {
  const navigate = useNavigate();

  const signOutHandle = useCallback(() => {
    // Limpa o localStorage
    localStorage.removeItem('idExterno.inscricao');
    localStorage.removeItem('idExterno.candidato');
    localStorage.removeItem('login_hint');
    localStorage.removeItem('as');
    removeCookie('userInfo');

    navigate('login?signout=true');
  }, [navigate]);

  return (
    <S.LogoutWrapper data-testid="signout">
      <LftLink label="Sair" lftClick={signOutHandle} tabIndex={0} />
    </S.LogoutWrapper>
  );
};
