import { apiConfigs } from './api/api.configs';
import { authConfigs } from './auth/auth.configs';
import { cursosComplementaresConfigs } from './cursos-complementares/cursos-complementares.configs';
import { brand, env, host, portal, savaEstudante, home } from './environment/environment.configs';
import { isEnabled } from './is-enabled/is-enabled.config';
import { Textos } from './textos/textos.config';

export class ConfigsManager {
  private static instance: ConfigsManager;

  private constructor() {
    /**
     * Construtor deve ser privado para sempre usar via getInstance
     */
  }

  public static getInstance(): ConfigsManager {
    if (!this.instance) {
      ConfigsManager.instance = new ConfigsManager();
    }

    return ConfigsManager.instance;
  }

  get host() {
    return host;
  }

  get brand() {
    return brand;
  }

  get portal() {
    return portal;
  }

  get home() {
    return home;
  }

  get savaEstudante() {
    return savaEstudante;
  }

  get authConfigs() {
    const { config } = authConfigs().env(env).brand(brand).get();

    return config;
  }

  get apiConfigs() {
    return apiConfigs().env(env).brand(brand).get();
  }

  get cursosComplementaresPadrao() {
    return cursosComplementaresConfigs(brand);
  }

  get isEnabled() {
    return (flag: string) => isEnabled(flag, env);
  }

  textos(wildcard: string) {
    return Textos.getInstance({ brand }).textos(wildcard);
  }
}
