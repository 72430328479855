import styled from '@emotion/styled';
import { HeaderStyledProps } from './header.types';

const Container = styled.header<HeaderStyledProps>`
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  padding: 0 40px 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.NeutralColorLightPure};

  @media (max-width: 768px) {
    ${({ isLead }) =>
      isLead &&
      `
      flex-wrap: wrap;
      height: auto;
      padding-right: 1.5rem;

      a {
        order: 1;
        width: 50%;
      }

     #menu-sanduiche {
        order: 2;
        margin-left: 20%;
        justify-content: flex-end;
      }

      .skeleton-container {
        order: 3;
        margin-left: 1.5rem;
        margin-bottom: 1rem;
      }

      .listaCursos {
        order: 3;
        flex: 1;
        width: 100%;
        margin-left: 1.5rem;
        margin-bottom: 1rem;
      }
    `}
  }
`;

const S = {
  Container,
};

export default S;
