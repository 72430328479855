import kebabCase from 'lodash/kebabCase';

export const obterPrimeiraPalavra = (nome: string) => {
  if (!nome) return '';
  return nome.split(' ')[0];
};

export const converterParaKebabCase = (nome: string) => {
  return kebabCase(nome?.toLowerCase());
};
