import LftAvatar from '@lift/design-system-react-web/dist/components/LftAvatar';
import LftSkeleton from '@lift/design-system-react-web/dist/components/LftSkeleton';
import { useCallback } from 'react';
import S from './avatar-usuario.styles';
import { AvatarUsuarioProps } from './avatar-usuario.types';

/**
 * @param emailUsuario
 * Quando não enviado emailUsuario, o avatar fica em modo de skeleton
 */
export const AvatarUsuario: React.FC<AvatarUsuarioProps> = ({ emailUsuario }) => {
  const Skeleton = useCallback(
    () => (
      <S.AvatarWrapper>
        <div>
          <LftSkeleton shape="circle" />
        </div>
      </S.AvatarWrapper>
    ),
    [],
  );

  return emailUsuario ? (
    <S.AvatarWrapper>
      <div>
        <LftAvatar description={emailUsuario} />
      </div>
    </S.AvatarWrapper>
  ) : (
    <Skeleton />
  );
};
