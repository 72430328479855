import MenuIcon from '@lift/assets/dist/assets/globals/icons/menu';
import LftIconButton from '@lift/design-system-react-web/dist/components/LftIconButton';
import { useStore } from 'effector-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { $isLead } from '../../stores/usuario/usuario.store';
import { setBodyProps } from '../../utils/set-body-props/set-body-props';
import DadosUsuario from '../dados-usuario/dados-usuario.component';
import { Logout } from '../logout/logout.component';
import { MenuAcessibilidade } from '../menu-acessibilidade';
import { ConteudoMenuSanduicheFragment } from './fragments/conteudo-menu-sanduiche/conteudo-menu-sanduiche.fragment';
import S from './menu-sanduiche.styles';

const MenuSanduiche: React.FC = () => {
  const { pathname } = useLocation();
  const { isEnabled } = ConfigsManager.getInstance();
  const isLead = useStore($isLead);

  const [open, setOpen] = useState(false);
  const [rotaAtual, setRotaAtual] = useState<string | undefined>(pathname);

  useEffect(() => {
    if (open) {
      setBodyProps({ overflow: 'hidden', ariaHidden: true });
      return;
    }

    setBodyProps({ overflow: 'visible', ariaHidden: false });
  }, [open]);

  const toggleMenu = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (rotaAtual !== pathname) setOpen(false);

    setRotaAtual(pathname);
  }, [pathname, rotaAtual]);

  return (
    <>
      <LftIconButton
        icon={MenuIcon}
        size="lg"
        lftClick={toggleMenu}
        dataTestId="abrir-menu-sanduiche"
        ariaLabel="Abrir menu"
        id="menu-sanduiche"
      />

      {open && (
        <ConteudoMenuSanduicheFragment
          conteudo={
            <S.MenuSanduicheContent>
              <DadosUsuario />
            </S.MenuSanduicheContent>
          }
          rodape={
            <S.RodapeWrapper>
              {(isLead || isEnabled('FF_EXIBE_BOTAO_SAIR')) && <Logout />}

              <MenuAcessibilidade dropdownPosition="top" />
            </S.RodapeWrapper>
          }
          onClose={toggleMenu}
          menuAberto={open}
        />
      )}
    </>
  );
};

export default MenuSanduiche;
