import axios from 'axios';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { buildUrlParams } from '../../utils/cursos-complementares/cursos-complementares';
import {
  CodigosApiResponse,
  ConsultarCodigosCursosComplementaresService,
} from './consultar-codigos-cursos-complementares.types';

const {
  apiConfigs: { api },
} = ConfigsManager.getInstance();

export const consultarCodigosCursosComplementaresService: ConsultarCodigosCursosComplementaresService = async ({
  token,
  idCandidato,
  codigoCurso,
  modalidade,
  tipoCurso,
  usuarioLead,
}) => {
  const obterParams = () => {
    if (usuarioLead) {
      return buildUrlParams({ codigoCurso, modalidade, tipoCurso });
    } else {
      return `candidato=${idCandidato}`;
    }
  };

  const url = `${api}/cursos-complementares?${obterParams()}`;

  const headers = {
    authorization: `Bearer ${token}`,
  };

  const { data } = await axios.get<CodigosApiResponse>(url, { headers });

  return data;
};
