import { createEvent, createStore } from 'effector';
import { ResultadoAutenticacao } from './autenticacao.types';

// Eventos
export const resultadoAutenticacaoAtualizado = createEvent<ResultadoAutenticacao>();
export const load = createEvent();

// Stores
export const $autenticacao = createStore<ResultadoAutenticacao>({
  autenticado: false,
  pendente: true,
  erro: null,
  token: null,
})
  .on(load, () => {
    const resultadoArmazenamentoLocal = localStorage.getItem('as');

    if (resultadoArmazenamentoLocal) {
      const valor = atob(resultadoArmazenamentoLocal);

      return JSON.parse(valor);
    }

    return {
      autenticado: false,
      pendente: true,
      erro: null,
      token: null,
    };
  })
  .on(resultadoAutenticacaoAtualizado, (_, valor) => valor);

$autenticacao.watch((valor) => {
  localStorage.setItem('as', btoa(JSON.stringify(valor)));
});
