import { useStore } from 'effector-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfigsManager } from '../../../../infra/configs/configs.manager';
import { $curso } from '../../../../stores/curso/curso.store';
import { $isLead, usuarioAtualizado } from '../../../../stores/usuario/usuario.store';
import { getTipoCurso, transformarTipoCurso } from '../../../../utils/redirect-home/redirect-home.types';
import { PushPageLoaded } from './page-datalayer.hook.types';

const { brand } = ConfigsManager.getInstance();

export const usePageDatalayer = () => {
  const { pathname } = useLocation();
  const [pathnameReal, setPathnameReal] = useState<string | null>(null);
  const isLead = useStore($isLead);
  const curso = useStore($curso);
  const tipoCursoRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (pathname !== '/') {
      setPathnameReal(pathname);
    }
  }, [pathname]);

  const pushPageLoadedDatalayer = useCallback((pathname: string, tipoCursoRef: string | undefined) => {
    const pushData: PushPageLoaded = {
      event: 'page_loaded',
      page_details: {
        path: pathname,
        brand,
        type: tipoCursoRef,
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer.push(pushData);
  }, []);

  const atualizaTipoCursoCandidato = useCallback(
    (pathnameReal: string) => {
      if (tipoCursoRef.current) {
        return pushPageLoadedDatalayer(pathnameReal, tipoCursoRef.current);
      } else {
        const unsubscribe = usuarioAtualizado.watch((usuario) => {
          const tipoCurso = usuario?.curso?.tipo ? transformarTipoCurso[usuario?.curso?.tipo] : undefined;
          tipoCursoRef.current = tipoCurso ? getTipoCurso[tipoCurso] : undefined;
          pushPageLoadedDatalayer(pathnameReal, tipoCursoRef.current);
        });
        return { unsubscribe };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLead, pushPageLoadedDatalayer],
  );

  useEffect(() => {
    if (pathnameReal) {
      if (isLead) {
        tipoCursoRef.current = curso?.tipo ? getTipoCurso[curso.tipo] : undefined;
        return pushPageLoadedDatalayer(pathnameReal, tipoCursoRef.current);
      }
      atualizaTipoCursoCandidato(pathnameReal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atualizaTipoCursoCandidato, isLead, pathnameReal, pushPageLoadedDatalayer]);
};
