import { Assert } from '@aura/core-lib';
import { useListaIdsCursosComplementares } from '@aura/shared.cursos-complementares-frontend';
import { createEvent } from 'effector';
import { useStore } from 'effector-react';
import { useCallback } from 'react';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { consultarCodigosCursosComplementaresService } from '../../services/consultar-codigos-cursos-complementares/consultar-codigos-cursos-complementares.service';
import { $autenticacao } from '../../stores/autenticacao/autenticacao.store';
import { $curso } from '../../stores/curso/curso.store';
import { $isLead, $usuario } from '../../stores/usuario/usuario.store';
import { CodigosCursosComplementaresResult } from './codigos-cursos-complementares.types';

export const codigosCursosAtualizadosEvent = createEvent<string[]>();

const { cursosComplementaresPadrao } = ConfigsManager.getInstance();

export const useCodigosCursosComplementares = (): CodigosCursosComplementaresResult => {
  const { token } = useStore($autenticacao);
  const isLead = useStore($isLead);
  const curso = useStore($curso);
  const usuario = useStore($usuario);

  const fonteDadosCodigosCursosComplementares = useCallback((): Promise<string[]> => {
    Assert.nonNullable(usuario);
    Assert.nonNullable(curso);
    Assert.nonNullable(token);

    return consultarCodigosCursosComplementaresService({
      token,
      usuarioLead: isLead,
      idCandidato: usuario.id,
      codigoCurso: String(curso?.codigo),
      modalidade: String(curso?.modalidade),
      tipoCurso: String(curso?.tipo),
    });
  }, [token, usuario, curso]);

  const {
    codigosCursos: codigosCursosUsuario,
    error,
    retentar,
  } = useListaIdsCursosComplementares({
    fonteDados: fonteDadosCodigosCursosComplementares,
    precondicao: () => !!token && !!usuario,
    onSucessoRequisicao: (data) => codigosCursosAtualizadosEvent(data),
  });

  const codigosCursosPadrao = cursosComplementaresPadrao.map((cursoPadrao) => cursoPadrao.codigo);

  const codigosCursos = Array.from(new Set([...codigosCursosUsuario, ...codigosCursosPadrao])); // Remove ids duplicados entre os codigos de curso do usuario e padrao da marca

  return { codigosCursos, pendente: false, erro: !!error && codigosCursos.length === 0, retentar };
};
