import { Assert } from '@aura/core-lib';
import { useStore } from 'effector-react';
import { combineEvents } from 'patronum';
import { useCallback, useEffect } from 'react';
import { codigosCursosAtualizadosEvent } from '../../../../hooks/codigos-cursos-complementares/codigos-cursos-complementares.hook';
import { ConfigsManager } from '../../../../infra/configs/configs.manager';
import { IdExterno } from '../../../../models/usuario.model';
import { $curso, atualizaCurso, atualizaDisciplinas } from '../../../../stores/curso/curso.store';
import { $credenciais, $isLead, usuarioAtualizado } from '../../../../stores/usuario/usuario.store';
import { converterParaKebabCase } from '../../../../utils/formatters/converter-string/converter-string';
import { getTipoCurso } from '../../../../utils/redirect-home/redirect-home.types';
import {
  BaseEvents,
  CursoSelecionado,
  PushDadosUsuario,
  PushDadosUsuarioDatalayerParams,
  PushDadosUsuarioNivel1,
  RegistrationDataEvents,
  TiposConteudo,
} from './usuario-datalayer.types';

export const useUsuarioDatalayer = () => {
  const idExterno = useStore($credenciais);
  Assert.nonNullable(idExterno);
  const { brand } = ConfigsManager.getInstance();
  const isLead = useStore($isLead);
  const curso = useStore($curso);

  let usuarioId: string;
  let cursoSelecionado: CursoSelecionado;

  const obterTipoConteudo = (possuiDisciplinas: boolean, possuiCursosComplementares: boolean) => {
    if (possuiDisciplinas) {
      return possuiCursosComplementares ? TiposConteudo.DISCIPLINAS_E_CURSOS_COMPLEMENTARES : TiposConteudo.DISCIPLINAS;
    }

    return possuiCursosComplementares ? TiposConteudo.CURSOS_COMPLEMENTARES : TiposConteudo.NENHUM;
  };

  const pushDadosUsuarioDatalayerNivel1 = useCallback((idExterno: IdExterno) => {
    const pushData: PushDadosUsuarioNivel1 = {
      event: 'registration_data',
      details: {
        inscricao: {
          usuarioLead: isLead,
          idInscricao: idExterno.atila ?? idExterno.inscricao,
          idCandidato: idExterno.candidato,
        },
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer.push(pushData);
  }, []);

  const pushDadosUsuarioDatalayerNivel2 = useCallback((params: PushDadosUsuarioDatalayerParams) => {
    const pushData: PushDadosUsuario = {
      event: 'registration_data',
      details: {
        inscricao: {
          idLead: isLead ? params.usuario?.id : undefined,
          idInscricao: idExterno?.atila ?? idExterno?.inscricao,
          idCandidato: idExterno?.candidato,
          tipoCurso: params.usuario?.curso?.tipo ?? cursoSelecionado?.tipoCurso,
          curso: params.usuario?.curso?.nome ?? cursoSelecionado?.nomeCurso,
          modalidade: params.usuario?.curso?.modalidade,
          nomInstituicao: brand,
        },
        matricula: {
          codMatricula: params.usuario?.matriculaAcademica?.codigo,
        },
        conteudo: {
          tipoConteudo: obterTipoConteudo(params.possuiDisciplinas, params.possuiCursosComplementares),
        },
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer.push(pushData);
  }, []);

  const registrarNivel2 = useCallback(() => {
    const baseEvents: BaseEvents = {
      usuario: usuarioAtualizado,
      codigosCursos: codigosCursosAtualizadosEvent,
    };

    const events: RegistrationDataEvents = isLead
      ? { ...baseEvents, gradeCurricular: atualizaDisciplinas }
      : baseEvents;

    const $registrationDataEvents = combineEvents({
      events,
    });

    const watcher = $registrationDataEvents.watch((payload) => {
      const cursoSelecionadoLocal = localStorage.getItem('cursoSelecionado');
      if (isLead && cursoSelecionadoLocal) {
        const parsedSession = JSON.parse(cursoSelecionadoLocal);
        cursoSelecionado = {
          nomeCurso: converterParaKebabCase(parsedSession.nome),
          tipoCurso: getTipoCurso[parsedSession.tipo],
          disciplinas: payload?.gradeCurricular?.disciplinas,
        };

        usuarioId = payload.usuario.id;
      }

      const possuiDisciplinasUsuario = (payload.usuario.curso?.disciplinas?.length ?? 0) > 0;
      const possuiDisciplinasCursoSelecionado = (cursoSelecionado?.disciplinas?.length ?? 0) > 0;

      pushDadosUsuarioDatalayerNivel2({
        idExterno,
        usuario: payload.usuario,
        possuiDisciplinas: possuiDisciplinasUsuario || possuiDisciplinasCursoSelecionado,
        possuiCursosComplementares: payload.codigosCursos?.length > 0,
      });
    });

    return { desinscrever: () => watcher() };
  }, [idExterno, pushDadosUsuarioDatalayerNivel2]);

  const pushDadosUsuarioDatalayerNivel3 = useCallback((params: PushDadosUsuarioDatalayerParams) => {
    const pushData: PushDadosUsuario = {
      event: 'registration_data',
      details: {
        inscricao: {
          idLead: isLead ? usuarioId : undefined,
          idInscricao: idExterno?.atila ?? idExterno?.inscricao,
          idCandidato: idExterno?.candidato,
          tipoCurso: params.usuario?.curso?.tipo ?? cursoSelecionado?.tipoCurso,
          curso: params.usuario?.curso?.nome ?? cursoSelecionado?.nomeCurso,
          modalidade: params.usuario?.curso?.modalidade,
          nomInstituicao: brand,
        },
        matricula: {
          codMatricula: params.usuario?.matriculaAcademica?.codigo,
        },
        conteudo: {
          tipoConteudo: obterTipoConteudo(params.possuiDisciplinas, params.possuiCursosComplementares),
        },
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer.push(pushData);
  }, []);

  const registrarNivel3 = useCallback(() => {
    const events = {
      gradeCurricular: atualizaDisciplinas,
      codigosCursos: codigosCursosAtualizadosEvent,
      curso: atualizaCurso,
    };

    const $registrationDataEvents = combineEvents({
      events,
    });

    const watcher = $registrationDataEvents.watch((payload) => {
      cursoSelecionado = {
        nomeCurso: converterParaKebabCase(payload?.curso?.nome),
        tipoCurso: getTipoCurso[payload?.curso?.tipo],
        disciplinas: payload?.gradeCurricular?.disciplinas,
      };

      const possuiDisciplinasUsuario = (payload.gradeCurricular?.disciplinas?.length ?? 0) > 0;
      const possuiDisciplinasCursoSelecionado = (cursoSelecionado?.disciplinas?.length ?? 0) > 0;

      pushDadosUsuarioDatalayerNivel3({
        idExterno,
        possuiDisciplinas: possuiDisciplinasUsuario || possuiDisciplinasCursoSelecionado,
        possuiCursosComplementares: payload.codigosCursos?.length > 0,
      });
    });

    return { desinscrever: () => watcher() };
  }, [idExterno, pushDadosUsuarioDatalayerNivel2]);

  useEffect(() => {
    pushDadosUsuarioDatalayerNivel1(idExterno);

    const watcherNivel2 = registrarNivel2();

    return () => {
      watcherNivel2.desinscrever();
    };
  }, [idExterno, pushDadosUsuarioDatalayerNivel1, registrarNivel2]);

  useEffect(() => {
    const watcherNivel3 = registrarNivel3();
    return () => {
      watcherNivel3.desinscrever();
    };
  }, [curso?.nome]);
};
