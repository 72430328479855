import LftTagSelect from '@lift/design-system-react-web/dist/components/LftTagSelect';
import { useState } from 'react';
import { converterParaKebabCase } from '../../../../utils/formatters/converter-string/converter-string';
import S from './lista-tag.styles';
import { IListaTagProps } from './lista-tag.types';

export const ListaTag: React.FC<IListaTagProps> = ({
  list,
  selectedTagValue,
  defaultValue,
  setSelectedTagValue,
}: IListaTagProps) => {
  const [checkedId, setCheckedId] = useState(selectedTagValue || defaultValue);

  const handleTagClick = (value: string) => {
    setCheckedId(value);
    setSelectedTagValue(value);
  };

  return (
    <S.Lista>
      {list.map((tag) => (
        <LftTagSelect
          key={tag.value}
          selected={tag.value === checkedId}
          lftClick={() => handleTagClick(tag.value)}
          dataElement={`button_${converterParaKebabCase(tag.label)}`}
          {...tag}
        />
      ))}
    </S.Lista>
  );
};
