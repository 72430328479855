import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';
import { Curso } from '../../models/usuario.model';
import { $isLead, usuarioSelecionado } from '../usuario/usuario.store';

export const atualizaCurso = createEvent<Curso>();
export const loadCurso = createEvent();
export const atualizaDisciplinas = createEvent<Curso>();
let isLead: boolean | undefined;

export const $curso = createStore<Curso | null>(null)
  .on(usuarioSelecionado, (_, value) => value.curso)
  .on(atualizaCurso, (_, value) => value)
  .on(atualizaDisciplinas, (state, value) => {
    if (!state) {
      return null;
    }
    return { ...state, disciplinas: value.disciplinas };
  })
  .on(loadCurso, () => {
    isLead = useStore($isLead) ?? undefined;
    const valorCursoSelecionado = localStorage.getItem('cursoSelecionado');
    if (isLead && valorCursoSelecionado) {
      return JSON.parse(valorCursoSelecionado);
    }
    return null;
  });
