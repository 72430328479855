import { LftListMenuItem } from '@lift/design-system-react-web/dist/components';
import LftInputSearch from '@lift/design-system-react-web/dist/components/LftInputSearch';
import { useRef, useState } from 'react';
import { ListaTag } from '../../features/disciplinas/components/lista-tag/lista-tag.component';
import { atualizaInputRef } from '../../stores/input-search-ref/input-search-ref.store';
import { converterParaKebabCase } from '../../utils/formatters/converter-string/converter-string';
import S from './input-search.styles';
import { InputSearchProps } from './input-search.types';

export const InputSearch = <T extends { nome: string; tipo: string }>({
  tag,
  inputSearch,
  listaMenu,
}: InputSearchProps<T>) => {
  const inputSearchRef = useRef(null);
  const [exibeLista, setExibeLista] = useState(false);
  const [value, setValue] = useState<string>('');

  document.addEventListener('click', (event) => {
    const target = event.target as HTMLElement;
    const inputArea = document.getElementById('input-container');

    if (inputArea && !inputArea.contains(target)) {
      setExibeLista(false);
    }
  });

  return (
    <S.Container className={inputSearch?.className} id="input-container">
      {inputSearch && (
        <LftInputSearch
          ref={atualizaInputRef(inputSearchRef)}
          lftChange={(value: string) => setValue(value)}
          lftClickInput={() => {
            inputSearch.onClick?.();
            setExibeLista(true);
          }}
          lftFocus={() => {
            inputSearch.onFocus?.();
          }}
          value={value}
          placeholder={inputSearch.placeholder}
          dataTestId="input-search"
          dataElement={inputSearch?.dataElement}
          cleanOnSubmit={false}
        />
      )}
      {exibeLista && (
        <S.Content data-testid="content" data-section={inputSearch?.dataSection}>
          {tag?.tagPropsMapeadas && tag?.tagPropsMapeadas.length > 0 && (
            <S.ListaTags data-testid="lista-tags">
              <ListaTag
                list={tag.tagPropsMapeadas}
                selectedTagValue={tag.tagSelecionada}
                setSelectedTagValue={tag.setTagSelecionada}
                defaultValue={tag.tagDefault}
              />
            </S.ListaTags>
          )}
          <S.ListaCursos>
            {listaMenu?.itensFiltrados?.map(
              (item, key) =>
                item?.nome?.toLocaleLowerCase().includes(value?.toLocaleLowerCase()) && (
                  <LftListMenuItem
                    key={key.valueOf()}
                    id={item.nome}
                    value={item.nome}
                    lftClick={() => {
                      listaMenu?.onClick?.();
                      listaMenu.atualizaItem(item);
                      setExibeLista(false);
                      setValue('');
                    }}
                    dataElement={converterParaKebabCase(item.nome)}
                    dataInfo={converterParaKebabCase(tag?.tagSelecionada ?? item.tipo)}
                  >
                    {item.nome}
                  </LftListMenuItem>
                ),
            )}
          </S.ListaCursos>
        </S.Content>
      )}
    </S.Container>
  );
};
