export interface RedirectHomeProps {
  codCurso?: number;
  tipoCurso?: string;
  nomeCurso?: string;
}

export const getTipoCurso: Record<string, string> = {
  GRAD: 'graduacao',
  POS: 'pos-graduacao',
  TEC: 'cursos-tecnicos-e-profissionalizantes',
};

export const transformarTipoCurso: Record<string, string> = {
  GRADUACAO: 'GRAD',
  GRADUACAO_TECNOLOGICA: 'GRAD',
  ESPECIALIZACAO: 'POS',
  FORMACAO_PROFISSIONAL_NIVEL_TECNICO: 'TEC',
};
