import styled from '@emotion/styled';

const AppContainer = styled.div`
  display: flex;
  height: 100%;
`;

const SidebarContainer = styled.aside`
  width: 65px;
  height: 100%;
`;

const MainContainer = styled.main`
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.NeutralColorLightPure};
`;

const CursoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  min-width: 320px;
`;

const HeaderAsideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const SkeletonContainer = styled.div`
  width: 25rem;
  margin-right: 31px;

  div {
    border-radius: 0.5rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;

const S = {
  MainContainer,
  AppContainer,
  SidebarContainer,
  CursoWrapper,
  HeaderAsideContainer,
  SkeletonContainer,
};

export default S;
