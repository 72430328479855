import { Contexto } from '../environment.types';

export const yduqs: Contexto[] = [
  {
    host: 'http://localhost',
    portal: '',
    home: '',
    savaEstudante: '',
    env: 'local',
    brand: 'yduqs',
  },
];
