import styled from '@emotion/styled';

const Lista = styled.div`
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
`;

const S = {
  Lista,
};

export default S;
