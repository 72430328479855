export enum ModalidadeRest {
  TOTAL_EAD_REST = 'T',
  SEMIPRESENCIAL_REST = 'S',
  PRESENCIAL_REST = 'P',
  AO_VIVO_REST = 'V',
  FLEX_REST = 'SF',
  TELEPRESENCIAL_REST = 'L',
}

export enum Modalidade {
  GRAD = 'GRAD',
  POS = 'POS',
  TEC = 'TEC',
}
